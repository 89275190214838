/* Základní rozvržení */
.launched_test {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    position: relative;
    font-family: 'Roboto', sans-serif;
    background-color: #f0f4f8;
    height: 100vh;
}

.test_header {
    position: absolute;
    top: 10px;
    text-align: center;
    width: 100%;
    font-size: 18px;
    color: #007bff;
    font-weight: bold;
}

/* Kontejner otázky */
.question-container {
    width: 100%;
    max-width: 600px;
    background: white;
    border-radius: 15px;
    padding: 30px;
    margin: 10px 0;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    transition: transform 0.4s ease, opacity 0.4s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.question_body {
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
}

/* Obsah otázky */
.question_header, .question_footer {
    width: 100%;
    text-align: center;
}

.question_header p, .question_footer p {
    font-size: 18px;
    color: #555;
}

.question_text {
    font-size: 24px;

    font-weight: bold;
    color: #333;
}

textarea {
    width: 100%;
    height: 120px;
    margin-top: 15px;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 16px;
    resize: vertical;
}

/* Tlačítka pro navigaci */
.button_previous,
.button_next {
    background-color: #4c8bf5;
    color: white;
    border: none;
    padding: 10px;
    font-size: 28px;
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.button_previous {
    left: 20px;
}

.button_next {
    right: 20px;
}

/* Kontejner pro tlačítko odeslání */
.submit-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.submit-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 18px;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #45a049;
}

/* Animace otázky */
.slide-in-left {
    animation: slideInLeft 0.4s forwards;
}

.slide-out-right {
    animation: slideOutRight 0.4s forwards;
}

.slide-in-right {
    animation: slideInRight 0.4s forwards;
}

.slide-out-left {
    animation: slideOutLeft 0.4s forwards;
}

@keyframes slideInLeft {
    from { transform: translateX(-100%); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
}

@keyframes slideOutRight {
    from { transform: translateX(0); opacity: 1; }
    to { transform: translateX(100%); opacity: 0; }
}

@keyframes slideInRight {
    from { transform: translateX(100%); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
}

@keyframes slideOutLeft {
    from { transform: translateX(0); opacity: 1; }
    to { transform: translateX(-100%); opacity: 0; }
}

/* Modální potvrzení */
.submit-confirm-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.modal-content {
    background: white;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.confirm-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
    font-size: 16px;
}

.cancel-button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.confirm-button:hover {
    background-color: #218838;
}

.cancel-button:hover {
    background-color: #c82333;
}

/* Zobrazení po odeslání */
.submission-confirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 20px;
}

.check-icon {
    color: #28a745;
    font-size: 60px;
    margin-bottom: 10px;
    animation: pop 0.5s ease;
}

@keyframes pop {
    0% { transform: scale(0); }
    100% { transform: scale(1); }
}

/* Responzivní styl pro menší obrazovky */
@media (max-width: 768px) {
    .question-container {
        padding: 20px;
    }

    .test_header {
        font-size: 16px;
    }

    .question_text {
        font-size: 20px;
    }

    textarea {
        font-size: 14px;
        height: 100px;
    }

    .button_previous,
    .button_next {
        font-size: 24px;
        padding: 8px;
        position: static;
        transform: none;
        margin: 10px;
    }

    .submit-button {
        padding: 12px 24px;
        font-size: 16px;
    }

    .button-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 400px;
        margin-top: 10px;
    }
}

@media (max-width: 480px) {
    .test_header {
        font-size: 14px;
    }

    .question_text {
        font-size: 18px;
    }

    .button_previous,
    .button_next {
        font-size: 20px;
        padding: 6px;
        position: static;
        transform: none;
        margin: 5px;
    }

    .submit-button {
        padding: 10px 20px;
        font-size: 14px;
    }

    .button-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 350px;
        margin-top: 10px;
    }
}
