/* Globální styly */
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #f9f9f9;
    font-family: 'Arial', sans-serif;
    color: #333;
    line-height: 1.6;
    min-height: 100vh;
}

/* Reset box-sizing */
*, *::before, *::after {
    box-sizing: inherit;
}

/* Hlavní sekce */
section {
    padding: 20px;
    max-width: 98%;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
}