

/* Container for all questions */
.All-Questions {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    gap: 20px;
    background-color: #ffffff;
    border-radius: 10px;
}

.comp-question input{
width: 95%;
}

/* Styling for each individual question */
.One-Questions {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    height: 100%; /* Ensure container height */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    position: relative;
}

.One-Questions:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.One-Questions.dragging {
    opacity: 0.9;
    z-index: 2;
    transform: scale(1.02);
    background-color: #e0f7ff; /* Highlighted background when dragging */
}

/* Drag handle styling */
.drag-handle {
    cursor: grab;
    display: flex;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 10px;
    color: #007BFF;
    font-size: 20px;
}

.drag-handle:active {
    cursor: grabbing;
}

/* Styling for question content */
.One-Questions-Question {
    font-size: 20px;
    font-weight: bold;
    color: #007BFF;
    margin-bottom: 10px;
}

.One-Questions-Description {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

.One-Questions-Answer,
.One-Questions-Points {
    margin-bottom: 10px;
    display: flex;
}

.One-Questions-Answer p,
.One-Questions-Points p {
    margin: 0px 5px 0px 0px;
    font-weight: normal;
}

/* Styling for the textarea container */
.One-Questions-Answer {
    display: flex;
    flex-direction: column; /* Ensure vertical stacking */
    gap: 10px; /* Spacing between label and textarea */
    margin-bottom: 20px; /* Spacing after textarea container */
}



/* Styling for the textarea */
textarea {
    width: 100%; /* Full width to fit container */
    min-height: 120px; /* Minimum height for visibility */
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
    resize: vertical; /* Allow vertical resizing */
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    background-color: #f9f9f9; /* Light background for better readability */
    color: #333; /* Text color */
    display: block; /* Ensure block element behavior */
    margin: 0px; /* Spacing around textarea */

}

/* Focused textarea styling */
textarea:focus {
    border-color: #007BFF; /* Blue border for focus state */
    outline: none; /* Remove default outline */
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.5); /* Add shadow effect */
    background-color: #ffffff; /* Brighter background on focus */
}

/* Styling for textarea when hovering */
textarea:hover {
    border-color: #0056b3; /* Slightly darker blue for hover */
}

/* Styling for answers */
.Answers {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    color: #ffffff;
    margin: 0px;
}

.Answer {
    background-color: #007BFF;
    color: #ffffff;
    padding: 2px 8px;
    border-radius: 5px;
    font-size: 14px;
}

/* Input and select styling for inline editing */
input[type="text"],
input[type="number"],
select {
    font-size: 16px;
    padding: 2px 5px;
    width: auto;
    min-width: 80px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.2s ease;
    box-sizing: content-box;
}

input[type="text"]:focus,
input[type="number"]:focus,
select:focus {
    border-color: #007BFF;
    outline: none;
}


/* Styling for action buttons */
.question-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: auto; /* Push the button container to the bottom */
}

.btn-delete {
    background-color: #FF4136;
    color: white;
    border: none;
    width: 100%;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.1s ease;
}

.btn-delete:hover {
    background-color: #b92c22;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
}

.btn-delete:active {
    background-color: #d9382c;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transform: translateY(0);
}

.btn-delete svg {
    margin-right: 8px;
    font-size: 16px;
}

/* Styling for "Add New Question" button */
.newQuestion-box {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 20px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
}

.newQuestion-box:hover {
    background-color: #e0f2ff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px); /* Slight lift on hover */
}

.newQuestion {
    font-size: 50px;
    color: #007BFF;
    transition: transform 0.6s ease; /* Smooth rotation */
}

.newQuestion-box:hover .newQuestion {
    transform: rotate(360deg); /* Full rotation on hover */
}

/* Header styling for test details */
.Test-header {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.Test-header h2 {
    font-size: 24px;
    color: #007BFF;
    font-weight: bold;
}

.Test-header p,
.Test-header input,
.Test-header select {
    font-size: 18px;
    color: #555;
    margin: 0 0 10px;
}

.Test-header input,
.Test-header select {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.Test-header input:focus,
.Test-header select:focus {
    border-color: #007BFF;
    outline: none;
}

.test-button-save {
    display: flex; /* Flexbox pro horizontální uspořádání tlačítek */
    gap: 10px; /* Mezera mezi tlačítky */
    position: absolute;
    top: 10px;
    right: 10px;
}

.test-button-save button {
    background-color: #007BFF;
    padding: 10px 20px; /* Přidána horizontální mezera */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    display: flex; /* Flexbox pro zarovnání obsahu */
    align-items: center; /* Vertikální zarovnání obsahu */
    justify-content: center; /* Horizontální zarovnání obsahu */
    gap: 5px; /* Mezera mezi ikonou a textem */
    transition: background-color 0.3s ease, transform 0.1s ease;
}



.test-button-save button:hover {
    background-color: #0056b3;
}
