/* Moderní styl pro hlavičku s QR kódem */
.Launched-test-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(135deg, #f0f8ff, #dbe9f4);
    color: #333333;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.Launched-test-header h2 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #007BFF;
}

.all-connected-student {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    padding: 20px;
}

/* Styling pro každého studenta s cyklickým výběrem více barev */
.one-student {
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 0;
    color: #FFFFFF; /* Bílý text */
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    animation: flyIn 0.5s ease-out forwards, float 4s ease-in-out infinite;
    opacity: 0; /* Výchozí neviditelný stav */
    transform: translateX(100%); /* Výchozí pozice mimo obrazovku vpravo */
}

/* Hover efekt */
.one-student:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

/* Animace pro příchod studenta */
@keyframes flyIn {
    from {
        opacity: 0;
        transform: translateX(100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

/* Animace pro individuální pohyb studentů */
@keyframes float {
    0% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0); }
}

/* Cyklický výběr více barev pro studenty */
.one-student:nth-child(8n+1) { background-color: #FF6B6B; }
.one-student:nth-child(8n+2) { background-color: #6BCBFF; }
.one-student:nth-child(8n+3) { background-color: #FFD93D; }
.one-student:nth-child(8n+4) { background-color: #1DD1A1; }
.one-student:nth-child(8n+5) { background-color: #B15FDC; }
.one-student:nth-child(8n+6) { background-color: #FF8C42; }
.one-student:nth-child(8n+7) { background-color: #4B6587; }
.one-student:nth-child(8n+8) { background-color: #A3CB38; }

/* Animace delay pro individuální pohyb studentů */
.one-student:nth-child(1) { animation-delay: 0s, 0s; }
.one-student:nth-child(2) { animation-delay: 0s, 0.3s; }
.one-student:nth-child(3) { animation-delay: 0s, 0.6s; }
.one-student:nth-child(4) { animation-delay: 0s, 0.9s; }
.one-student:nth-child(5) { animation-delay: 0s, 1.2s; }
.one-student:nth-child(6) { animation-delay: 0s, 1.5s; }
.one-student:nth-child(7) { animation-delay: 0s, 1.8s; }
.one-student:nth-child(8) { animation-delay: 0s, 2.1s; }
.one-student:nth-child(9) { animation-delay: 0s, 2.4s; }
.one-student:nth-child(10) { animation-delay: 0s, 2.7s; }

/* Styl pro název studenta */
.one-student .studen-name {
    color: #FFFFFF; /* Bílý text */
    margin: 0; /* Odstranění spodního marginu */
    font-size: 1.2rem;
}

/* Styl pro tlačítko spuštění testu */
.start-test-button {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.start-test-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.start-test-button:active {
    transform: scale(1);
}

.test-link {
    color: #007BFF;
    text-decoration: none;
    font-weight: bold;
    margin-top: 15px;
    display: block;
    transition: color 0.3s ease;
}

.test-link:hover {
    color: #0056b3;
}
