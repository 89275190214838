.profile-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .profile-section {
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background: #f9f9f9;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .profile-avatar {
    text-align: center;
    margin-bottom: 15px;
  }
  
  .profile-avatar img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 3px solid #007bff;
    object-fit: cover;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .profile-avatar-upload {
    display: block;
    margin: 10px auto;
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .profile-avatar-upload:hover {
    background: #f0f0f0;
  }
  
  .profile-info {
    margin-bottom: 10px;
  }
  
  .profile-info p {
    margin: 5px 0;
    font-size: 14px;
    color: #555;
  }
  
  .button-group {
    display: flex;
    gap: 10px;
    margin-top: 15px;
  }
  
  button {
    padding: 10px 15px;
    background: #007bff; /* Původní modrá barva */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background 0.3s;
  }
  
  button:hover {
    background: #0056b3;
  }
  
  button.save-profile-button {
    background: #28a745; /* Zelená barva pro uložení */
  }
  
  button.save-profile-button:hover {
    background: #218838;
  }
  
  button.cancel-button {
    background: #007bff; /* Stejná modrá barva jako původní tlačítko */
  }
  
  button.cancel-button:hover {
    background: #0056b3;
  }
  
  button.edit-button {
    background: #007bff; /* Původní modrá barva */
  }
  
  button.edit-button:hover {
    background: #0056b3;
  }
  
  input[type="text"],
  input[type="password"],
  select {
    display: block;
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  input[type="text"]:focus,
  input[type="password"]:focus,
  select:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
  }
  
  select {
    background: white;
  }
  
  select:hover {
    background: #f8f8f8;
  }
  
  select option {
    padding: 5px;
  }
  
  .profile-section h3 {
    margin-bottom: 15px;
    color: #333;
    font-size: 18px;
    font-weight: bold;
  }
  
  .profile-section input[type="file"] {
    margin-top: 10px;
  }
  
  .profile-section:last-child {
    margin-bottom: 0;
  }
  
  /* Responsivní design pro menší obrazovky */
  @media (max-width: 600px) {
    .button-group {
      flex-direction: column;
      gap: 10px;
    }
  
    button {
      width: 100%;
    }
  }
  