/* Footer styl */
.footer {
    background-color: #007BFF;
    color: white;
    padding: 20px;
    text-align: center;
    margin-top: 40px;
    border-top: 4px solid #0056b3;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.footer-links, .footer-social {
    display: flex;
    gap: 20px;
}

.footer-links a, .footer-social a {
    color: white;
    text-decoration: none;
    font-size: 16px;
    transition: color 0.3s ease;
}

.footer-links a:hover, .footer-social a:hover {
    color: #f0f0f0;
}

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        gap: 15px;
    }

    .footer-links, .footer-social {
        flex-direction: column;
    }
}