.about-container {
    padding: 2rem;
    background: linear-gradient(135deg, #f0f8ff, #dbe9f4);
    color: #333;
    font-family: 'Arial', sans-serif;
    max-width: 98%;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
}

.about-hero {
    text-align: center;
    margin-bottom: 2rem;
}

.about-title {
    font-size: 2.5rem;
    color: #007acc;
    margin-bottom: 1rem;
}

.about-description {
    font-size: 1.4rem;
    color: #555;
    margin-bottom: 2rem;
    padding: 0 1rem;
}

.about-content {
    font-size: 1.2rem;
    line-height: 1.8;
    padding: 0 1.5rem;
    text-align: justify;
}

.about-content p {
    margin-bottom: 1rem;
}

.about-content strong {
    color: #0056b3;
    font-weight: 600;
}



