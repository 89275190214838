.teacher-test-monitor {
    font-family: 'Roboto', sans-serif;
    background-color: #f7f9fc;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 98%;
    margin: 0 auto;
}

.teacher-test-monitor h2 {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.teacher-test-monitor button {
    display: block;
    margin: 0 auto 20px auto;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.teacher-test-monitor button:hover {
    background-color: #0056b3;
}

.table-container {
    overflow-x: auto; /* Přidání horizontálního posuvníku, pokud je potřeba */
    margin-top: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
}

.teacher-test-monitor table {
    width: 100%;
    border-collapse: collapse;
    margin: 0;
}

.teacher-test-monitor th,
.teacher-test-monitor td {
    padding: 12px;
    text-align: center;
    border: 1px solid #e0e0e0;
    font-size: 1rem;
    white-space: nowrap;
}

.teacher-test-monitor th {
    background-color: #007bff;
    color: #fff;
    font-weight: bold;
    position: sticky;
    top: 0;
    z-index: 1;
}

.teacher-test-monitor tbody tr:nth-child(even) {
    background-color: #f2f4f7;
}

.teacher-test-monitor td {
    color: #555;
}

@media (max-width: 768px) {
    .teacher-test-monitor {
        padding: 10px;
    }

    .teacher-test-monitor h2 {
        font-size: 1.5rem;
    }

    .teacher-test-monitor table {
        font-size: 0.9rem;
    }

    .teacher-test-monitor th,
    .teacher-test-monitor td {
        padding: 8px;
    }

    .teacher-test-monitor button {
        width: 100%;
        font-size: 0.9rem;
    }
}
