/* Home Container */
.home-container {
  padding: 2rem;
  background: linear-gradient(135deg, #f0f8ff, #dbe9f4);
  color: #333;
  font-family: 'Arial', sans-serif;
  overflow: hidden;
  position: relative;
}


/* Hero Section */
.hero-section {
  text-align: center;
  margin-bottom: 3rem;
}

.hero-title {
  font-size: 3rem;
  color: #007acc;
  margin-bottom: 1rem;
}

.hero-description {
  font-size: 1.5rem;
  color: #555;
  margin-bottom: 2rem;
}

.cta-button {
  background-color: #007acc;
  color: white;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cta-button:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

/* Features Section */
.features-section {
  text-align: center;
  margin-bottom: 3rem;
}

.features {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.feature {
  background-color: #fff;
  border-radius: 10px;
  padding: 2rem;
  margin: 1rem;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0.9;
}

.feature:hover {
  transform: translateY(-5px);
  opacity: 1;
}

/* Testimonials Section */
.testimonial-section {
  text-align: center;
  margin-top: 3rem;
  background-color: #eaf6ff;
  padding: 2rem;
  border-radius: 10px;
}

.testimonial-section p {
  font-style: italic;
  color: #333;
  font-size: 1.2rem;
}

/* Responsivita */
@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
  }
  .hero-description {
    font-size: 1rem;
  }
  .features {
    flex-direction: column;
    align-items: center;
  }
  .feature {
    width: 90%;
  }
}
