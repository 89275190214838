/* Navbar.css */
header {
    width: 100%;
    background-color: #007BFF;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    margin-bottom: 20px; /* Větší mezera pod menu */
}

.navbar {
    max-width: 1200px;
    margin: 0 auto;
}

.navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Logo vlevo, menu uprostřed, ikona vpravo */
    padding: 15px 20px;
}


.Logo-full {
    fill:white ;
    max-width: 150px;
}

.navbar-links {
    display: flex;
    gap: 15px;
    align-items: center;
}

.link {
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 25px; /* Více zakulacené */
    color: white;
    transition: background-color 0.3s ease;
    font-weight: 500;
}

.link.active {
    background-color: #0056b3;
}

.link:hover {
    background-color: #0056b3;
}

.logout-button {
    background: none;
    border: none;
    cursor: pointer;
    font: inherit;
    color: white;
    padding: 10px 15px;
    border-radius: 25px; /* Více zakulacené */
    transition: background-color 0.3s ease;
}

.logout-button:hover {
    background-color: #0056b3;
}

.mobile-menu-icon {
    font-size: 1.8rem;
    color: white;
    cursor: pointer;
    display: none;
}

/* Responzivita */
@media (max-width: 768px) {
    .navbar-links {
        display: none; /* Skryjeme desktopové menu */
    }

    .mobile-menu-icon {
        display: block;
    }

    .navbar-container {
        justify-content: space-between;
    }

    .mobile-navbar-links {
        display: flex;
        flex-direction: column;
        background-color: #007BFF;
        width: 100%;
        padding: 10px 0;
    }

    .mobile-navbar-links .link,
    .mobile-navbar-links .logout-button {
        width: 100%;
        text-align: center;
        padding: 15px 0;
        border-radius: 0;
    }
}

/* Zajištění, že odkazy jsou viditelné na desktopu */
@media (min-width: 769px) {
    .mobile-navbar-links {
        display: none;
    }

    .mobile-menu-icon {
        display: none;
    }

    .navbar-container {
        justify-content: space-between;
    }
}
