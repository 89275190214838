/* Sessions_of_test.css */

/* Kořenové proměnné pro barvy a fonty */
:root {
    --primary-color: #007BFF;
    --secondary-color: #6c757d;
    --background-color: #ffffff;
    --text-color: #212529;
    --muted-text-color: #6c757d;
    --border-color: #dee2e6;
    --table-header-bg: #343a40;
    --table-header-color: #ffffff;
    --table-row-bg: #f8f9fa;
    --font-family: 'Roboto', sans-serif;
    --transition-speed: 0.5s; /* Zvýšení rychlosti přechodu pro plynulejší animaci */
}

/* Reset */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: var(--font-family);
    background-color: var(--background-color);
    color: var(--text-color);
}

.sessions-container {
    margin-bottom: 10px;
    padding: 10px;
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden; /* Zajištění, že se obsah nebude překrývat */
}

.expand-button {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Přidáno pro lepší zarovnání textu a ikony */
    background-color: var(--primary-color);
    color: var(--background-color);
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color var(--transition-speed), transform 0.3s ease;
}

.expand-button:hover {
    background-color: #0056b3; /* Příklad tmavší barvy */
    transform: translateY(-1px); /* Přidání mírného posunutí při hover */
}

.sessions-list {
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: height var(--transition-speed) ease, opacity var(--transition-speed) ease;
}

.sessions-list.expand {
    /* Výška bude dynamicky nastavena pomocí JavaScriptu */
    opacity: 1;
}

.session-item {
    padding: 10px;
    border-bottom: 1px solid var(--border-color);
}

.session-item:last-child {
    border-bottom: none;
}

.session-item p {
    margin: 5px 0;
}

.open-session-button {
    background-color: #2baa48;;
    color: var(--background-color);
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color var(--transition-speed), transform 0.3s ease;
}

.open-session-button:hover {
    background-color: #24913d; /* Příklad tmavší barvy */
    transform: translateY(-1px); /* Přidání mírného posunutí při hover */
}

.icon {
    display: flex;
}

/* Responsivní úpravy */
@media (max-width: 768px) {
    .sessions-container {
        padding: 15px;
    }

    .expand-button {
        padding: 8px 12px;
        font-size: 14px;
    }

    .session-item p {
        font-size: 14px;
    }

    .open-session-button {
        padding: 6px 10px;
        font-size: 14px;
    }
}
