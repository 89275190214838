/* Celkový kontejner */
.student-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Horizontální vycentrování */
    justify-content: center; /* Vertikální vycentrování */
    height: 100vh;
    background: linear-gradient(135deg, #f7fbff, #dbe9f4);
    color: #333;
    font-family: Arial, sans-serif;
    padding: 20px;
    box-sizing: border-box;
}

/* Vstupní pole pro jméno a čekací obrazovka */
.name-entry,
.waiting-screen,
.session-entry,
.end-test-message {
    display: flex; /* Flexbox pro vertikální rozložení */
    flex-direction: column; /* Vertikální rozložení */
    align-items: center; /* Horizontální vycentrování obsahu */
    text-align: center;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    margin: 10px;
}

/* Styl pro session-entry */
.session-entry {
    /* Stejné základní styly jako ostatní sekce */
}

.session-entry h2 {
    font-size: 1.5rem;
    color: #28a745; /* Zelená barva pro nadpis */
    margin-bottom: 20px;
}

.session-entry input {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-bottom: 20px;
    font-size: 16px;
    width: 100%;
    max-width: 300px; /* Zajistí, že pole nebude širší než 300px */
    transition: border-color 0.3s ease;
    text-align: center; /* Zarovnání textu na střed */
}

.session-entry input:focus {
    border-color: #28a745;
    outline: none;
}

.session-entry button {
    padding: 12px 24px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.session-entry button:hover {
    background-color: #218838;
    transform: translateY(-2px);
}

.session-entry button:active {
    background-color: #1e7e34;
    transform: translateY(0);
}

/* Styl pro end-test-message */
.end-test-message {
    /* Stejné základní styly jako ostatní sekce */
}

.end-test-message h2 {
    font-size: 1.5rem;
    color: #dc3545; /* Červená barva pro nadpis */
    margin-bottom: 20px;
}

.end-test-message p {
    font-size: 1rem;
    color: #555;
}

/* Nadpisy */
.name-entry h2,
.waiting-screen h2,
.session-entry h2,
.end-test-message h2 {
    font-size: 1.5rem;
    color: #007BFF;
    margin-bottom: 20px;
}

/* Vstupní pole */
.name-entry input,
.session-entry input {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-bottom: 20px;
    font-size: 16px;
    width: 100%;
    max-width: 300px; /* Zajistí, že pole nebude širší než 300px */
    transition: border-color 0.3s ease;
    text-align: center; /* Zarovnání textu na střed */
}

/* Styl pro focus na vstupním poli */
.name-entry input:focus,
.session-entry input:focus {
    border-color: #007BFF;
    outline: none;
}

/* Tlačítko */
.name-entry button,
.session-entry button {
    padding: 12px 24px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Hover a active stavy tlačítka */
.name-entry button:hover,
.session-entry button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

.name-entry button:active,
.session-entry button:active {
    background-color: #004494;
    transform: translateY(0);
}

/* Chybová zpráva */
.error-message {
    color: #FF4136;
    margin-top: 10px;
    font-size: 14px;
}

/* Čekací obrazovka */
.waiting-screen p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
}

/* Indikátor načítání */
.loading-indicator {
    width: 40px;
    height: 40px;
    border: 5px solid #007BFF;
    border-top: 5px solid #ffffff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 0 auto; /* Vycentrování indikátoru */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Media Queries pro responzivní design */
@media (max-width: 768px) {
    /* Přizpůsobení pro tablety */
    .student-container {
        padding: 15px;
    }
    
    .name-entry,
    .waiting-screen,
    .session-entry,
    .end-test-message {
        padding: 20px;
        max-width: 90%;
    }

    .name-entry h2,
    .waiting-screen h2,
    .session-entry h2,
    .end-test-message h2 {
        font-size: 1.4rem;
    }

    .name-entry input,
    .session-entry input,
    .name-entry button,
    .session-entry button {
        font-size: 14px;
        padding: 10px;
    }
    
    .loading-indicator {
        width: 35px;
        height: 35px;
    }
}

@media (max-width: 480px) {
    /* Přizpůsobení pro mobily */
    .name-entry,
    .waiting-screen,
    .session-entry,
    .end-test-message {
        padding: 15px;
        max-width: 100%;
    }

    .name-entry h2,
    .waiting-screen h2,
    .session-entry h2,
    .end-test-message h2 {
        font-size: 1.2rem;
    }

    .name-entry input,
    .session-entry input,
    .name-entry button,
    .session-entry button {
        font-size: 14px;
        padding: 8px;
    }
    
    .loading-indicator {
        width: 30px;
        height: 30px;
    }
}
