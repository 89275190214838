/* Styl pro registrační sekci */
.register-section {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Nadpis registrační stránky */
.register-heading {
  font-size: 2em;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

/* Styl pro formulář */
.register-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Styl pro vstupy ve formuláři */
.register-input {
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.register-input:focus {
  border-color: #007BFF;
  outline: none;
}

/* Tlačítko pro registraci */
.register-button {
  padding: 12px;
  font-size: 16px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.register-button:hover {
  background-color: #0056b3;
}

/* Chybová zpráva */
.register-error {
  color: red;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}

.register-success {
  color: green;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}

/* Tlačítko pro registraci přes Google */
.google-register-button {
  padding: 12px;
  font-size: 16px;
  width: 100%;
  background-color: #DB4437;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.google-register-button:hover {
  background-color: #c0392b;
}

/* Google ikona */
.google-icon {
  margin-right: 10px;
  font-size: 20px;
}

/* Role Selection Styling */
.role-selection {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 8px;
  gap: 10px;
}

.role-selection input {
  display: none;
}

.role-selection label {
  flex: 1;
  text-align: center;
  padding: 10px 0;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  background-color: #e0e0e0;
  color: #666;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.role-selection input:checked + label {
  background-color: #28a745; /* Zelená pro vybranou roli */
  color: white; /* Bílý text pro vybranou roli */
}
