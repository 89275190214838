/* Styl pro přihlašovací sekci */
.login-section {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Nadpis přihlašovací stránky */
  .login-heading {
    font-size: 2em;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Styl pro formulář */
  .login-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  /* Styl pro vstupy ve formuláři */
  .login-input {
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: border-color 0.3s ease;
  }
  
  .login-input:focus {
    border-color: #007BFF;
    outline: none;
  }
  
  /* Tlačítko pro přihlášení */
  .login-button {
    padding: 12px;
    font-size: 16px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
  
  /* Chybová zpráva */
  .login-error {
    color: red;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
  }
  
  /* Tlačítko pro přihlášení přes Google */
  .google-login-button {
    padding: 12px;
    font-size: 16px;
    width: 100%;
    background-color: #DB4437;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .google-login-button:hover {
    background-color: #c0392b;
  }
  
  /* Google ikona */
  .google-icon {
    margin-right: 10px;
    font-size: 20px;
  }