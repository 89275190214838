.profil-navbar {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 25px;
    background-color: rgba(255, 255, 255, 0.2); /* Jemné pozadí */
    transition: background-color 0.3s ease;
  }
  
  .profil-navbar:hover {
    background-color: rgba(255, 255, 255, 0.3); /* Zvýraznění na hover */
  }
  
  .profil-info {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .profil-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid white; /* Jemný obrys */
    object-fit: cover;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Jemný stín */
  }
  
  .profil-name {
    font-size: 14px;
    font-weight: bold;
    color: white;
    white-space: nowrap;
  }
  
  .profil-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background: white;
    color: black;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    padding: 10px 0;
    width: 150px;
    z-index: 1000;
  }
  
  .profil-dropdown button {
    display: block;
    width: 100%;
    background: none;
    border: none;
    text-align: left;
    padding: 10px 15px;
    font-size: 14px;
    cursor: pointer;
    color: black;
    transition: background-color 0.2s ease;
  }
  
  .profil-dropdown button:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  