/* Container styling */
.teacher-session-container {
    position: relative;
    max-width: 98%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease;
}

.teacher-session-container h2 {
    font-size: 26px;
    color: #007BFF;
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
    letter-spacing: 1px;
}

/* Wrapper to enable horizontal scrolling */
.table-wrapper {
    overflow-x: auto;
    width: 100%;
    padding-bottom: 1rem; /* Adds space for horizontal scrollbar */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

.teacher-session-table {
    width: 100%;
    min-width: 400px; /* Ensures table doesn't collapse on small screens */
    border-collapse: collapse;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

/* Table Header */
.teacher-session-table thead th {
    background-color: #007BFF;
    color: #fff;
    padding: 12px;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    min-width: 200px;
}

.teacher-session-table th, .teacher-session-table td {
    border: 1px solid #e0e0e0;
    padding: 12px;
    text-align: center;
    font-size: 15px;
    transition: background-color 0.3s ease;
}

/* Row styling */
.teacher-session-table tbody tr {
    transition: background-color 0.3s ease;
}

.teacher-session-table tbody tr:hover {
    background-color: #e3f2fd;
}

/* Striped row styling */
.teacher-session-table tbody tr:nth-child(odd) {
    background-color: #f7faff;
}

.teacher-session-table tbody tr:nth-child(even) {
    background-color: #ffffff;
}

/* Styling for the dynamic textarea */
.summary-cell .editable-input {
    background-color: transparent;
    border: none;
    color: inherit; /* Dědí barvu z okolního textu */
    width: 100%;
    overflow: hidden; /* Skryje posuvníky */
    resize: none; /* Zakáže ruční změnu velikosti */
    font-size: inherit; /* Dědí velikost písma z okolního textu */
    font-family: inherit; /* Dědí font z rodičovského elementu */
    padding: 0; /* Odstraní výplň pro plynulé zasazení do textu */
    line-height: inherit; /* Dědí výšku řádku pro konzistentní vzhled */
    outline: none; /* Odstraní obrys po kliknutí */
    cursor: text; /* Při najetí myší zobrazuje kurzor pro editaci */
    text-align: center; /* Centrovaný text, pokud je potřeba */
}

.summary-cell .editable-input:hover,
.summary-cell .editable-input:focus {
    border: 1px solid #1E90FF; /* Modrá barva okraje při hover nebo focus */
    box-shadow: 0px 0px 5px rgba(30, 144, 255, 0.5); /* Modro-stříbrný stín */
    background-color: rgba(230, 230, 250, 0.2); /* Jemné světlejší pozadí při editaci */
}

/* Score text styling */
.score-text {
    font-weight: bold;
    display: inline-flex;
    justify-content: center; /* Centers both input and static text */
    align-items: center; /* Aligns input and text vertically */
    white-space: nowrap; /* Prevents text from wrapping to the next line */
    padding-right: 30px;
}

.score-text input {
    background-color: transparent;
    min-width: 35px;
    border: none;
    text-align: right; /* Aligns score text to the right */
    direction: rtl; /* Moves the arrows to the left side */
    width: 30px; /* Adjust width to fit content */
    padding: 2px;
    outline: none;
    white-space: nowrap; /* Prevents the input value from wrapping */
}

/* Color coding for scores */
.max-score {
    color: green;
}

.min-score {
    color: red;
}

.normal-score {
    color: blue;
}



/* Styling for student names */
.student-name {
    font-weight: bold;
    color: #333;
    text-align: left;
    padding-left: 10px;
}

/* Answer cell styling */
.answer-cell {
    color: #555;
    text-align: center;
    font-size: 14px;
    transition: color 0.3s ease, background-color 0.3s ease;
}

.answer-cell:hover {
    background-color: #e0f7fa;
    color: #007BFF;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .teacher-session-container {
        padding: 10px;
    }

    .teacher-session-table th, .teacher-session-table td {
        padding: 8px;
        font-size: 13px;
    }

    .teacher-session-container h2 {
        font-size: 20px;
    }

    /* Allow horizontal scrolling on smaller screens */
    .table-wrapper {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}

/* Button styling */
.save-button {
    position: absolute;
    right: 20px;
    top: 20px;
    padding: 8px 16px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 20px;
}

.save-button:hover {
    background-color: #0056b3;
}
