/* Styl pro sekci s testy */
.All-Tests {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 20px;
    padding: 20px;
}

.One-Test {
    position: relative;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.One-Test:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.One-Test h2 {
    font-size: 24px;
    color: #007BFF;
}

.One-Test p {
    color: #555;
    font-size: 16px;
}

.One-Test a {
    display: inline-block;
    padding: 10px 15px;
    background-color: #007BFF;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.One-Test a:hover {
    background-color: #0056b3;
}

.One-Test .delete-test{
    border: none;
    background-color: #FF4136;
    position: absolute;
    margin-left: 5px;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    top: 10px;
    right: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: #ffffff;
}

.One-Test .delete-test:hover{
    background-color: #e6392b;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
}